import WhereFindSkillDrill from './Where-find-Skill-Drill.png';
import PromotionAndDemotion from './Promotion&Demotion.png';
import SetSkillDrill from './SetSkillDrill.png';
import QuestionBankFunctions from './QuestionBankFunctions.png';
import CreateClass from './create class.png';

const faqImages = {
    WhereFindSkillDrill,
    PromotionAndDemotion,
    SetSkillDrill,
    QuestionBankFunctions,
    CreateClass,
};

export default faqImages;